import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./Sidebar.scss";

function Sidebar({ toggleSidebar }) {
  const [inquiry, setInquiry] = useState("");
  const handleInquirySubmit = (e) => {
    e.preventDefault();
    console.log("문의사항:", inquiry);
    setInquiry("");
  };
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>메뉴</h2>
        <button onClick={toggleSidebar} className="close-sidebar">
          <IoMdClose />
        </button>
      </div>
      <form onSubmit={handleInquirySubmit}>
        <h3>문의사항</h3>
        <textarea
          value={inquiry}
          onChange={(e) => setInquiry(e.target.value)}
          placeholder="문의사항을 입력하세요..."
        />
        <button type="submit">제출</button>
      </form>
    </div>
  );
}

export default Sidebar;
