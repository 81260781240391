import React, { useState, useEffect, useRef } from "react";
import "./App.scss";
import assistantIcon from "./images/x.png";
import { TfiMenuAlt } from "react-icons/tfi";
import { IoSend } from "react-icons/io5";
import { FaStopCircle } from "react-icons/fa";
import Feedback from "./Components/Feedback/Feedback";
import ReactMarkdown from "react-markdown";
import Sidebar from "./Components/Sidebar/Sidebar";
import ChatGuide from "./Components/ChatGuide/ChatGuide";
import axios from "axios";

const SERVER_URL = "https://api.kc-softmax.com/api_service";
const WEBSOCKET_URL = "wss:///api.kc-softmax.com/stream/chatbot_service";

// const SERVER_URL = "http://0.0.0.0:8090/api_service";
// const WEBSOCKET_URL = "ws://0.0.0.0:8090/stream/chatbot_service";

const MAX_ATTEMPTS = 3;
const SEARCH_COMPANY_POLICY = "search_company_policy";
const GET_CONTACT_PERSON = "get_contact_person";

function App() {
  const [messages, setMessages] = useState([
    { role: "assistant", content: "무엇을 도와드릴까요?" },
  ]);
  const [mainError, setMainError] = useState("");
  const [generateMessage, setGenerateMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [socketState, setSocketState] = useState(WebSocket.CONNECTING);

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const websocketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const currentChannelRef = useRef(SEARCH_COMPANY_POLICY); // Track current channel
  const userRef = useRef(null); // Track user ID
  const connectionAttemptsRef = useRef(0); // Track number of connection attempts
  const isWebSocketConnectedRef = useRef(false); // Track if WebSocket is connected
  const isAuthenticatingRef = useRef(false); // Track if user is being authenticated

  useEffect(() => {
    if (isAuthenticatingRef.current) return;
    isAuthenticatingRef.current = true;
    const query = new URLSearchParams(window.location.search);
    const token = query.get("xxffqdqadf");
    if (token) {
      // // 백엔드에 authorization code 전송
      axios
        .post(`${SERVER_URL}/kc_group_user_verification`, { token })
        .then((response) => {
          if (response.status !== 200) {
            return;
          }
          if (response.data) {
            userRef.current = response.data;
            setAuthenticated(true);
          }
        })
        .catch((error) => {
          setMainError("서버가 응답하지 않습니다.");
        })
        .finally(() => {
          isAuthenticatingRef.current = false;
        });
    } else {
      setMainError("인증이 필요합니다.");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    // 초기화
    setIsGenerating(false);
    connectWebSocket();
    return () => {
      // Cleanup function to close WebSocket connection
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        websocketRef.current.close();
      }

      // Clear the timeout if the component unmounts
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      connectionAttemptsRef.current = 0; // Reset connection attempts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [inputMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const connectWebSocket = () => {
    if (!isWebSocketConnectedRef.current) {
      websocketRef.current = new WebSocket(WEBSOCKET_URL);
      setSocketState(WebSocket.CONNECTING);
      isWebSocketConnectedRef.current = true; // Update connection status
      websocketRef.current.onopen = () => {
        setSocketState(WebSocket.OPEN);
        connectionAttemptsRef.current = 0; // Reset connection attempts
      };

      websocketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        // 업데이트된 메시지를 임시로 보관
        const data = JSON.parse(message.data);
        setGenerateMessage((prevMessage) => {
          const updatedMessage = prevMessage + data.content;

          if (data.finished) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { role: "assistant", content: updatedMessage },
            ]);
            setGenerateMessage("");
            setIsGenerating(false);
          }
          return updatedMessage;
        });
      };

      websocketRef.current.onerror = (error) => {
        console.error("WebSocket 연결 에러:", error);
      };

      websocketRef.current.onclose = () => {
        isWebSocketConnectedRef.current = false; // Update connection status
        if (connectionAttemptsRef.current < MAX_ATTEMPTS) {
          setSocketState(WebSocket.CONNECTING);
          reconnectWebSocket(); // Attempt to reconnect
          console.log("Reconnecting WebSocket...");
        } else {
          setSocketState(WebSocket.CLOSED);
          console.log("WebSocket Closed no retry");
        }
      };
    }
  };

  const sendMessage = (message) => {
    websocketRef.current.send(
      JSON.stringify({
        channel: currentChannelRef.current,
        data: {
          user_id: userRef.current.id,
          content: message,
        },
      })
    );
    if (currentChannelRef.current !== SEARCH_COMPANY_POLICY) {
      currentChannelRef.current = SEARCH_COMPANY_POLICY;
    }
  };

  const stopGenerating = () => {
    // 중단
    // if (websocketRef.current) {
    //   websocketRef.current.close();
    // }
    // setIsGenerating(false);
  };

  const reconnectWebSocket = () => {
    connectionAttemptsRef.current += 1;
    console.log(
      `Reconnecting WebSocket... Attempt ${connectionAttemptsRef.current}`
    );

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }
    reconnectTimeoutRef.current = setTimeout(() => {
      if (socketState !== WebSocket.OPEN) {
        connectWebSocket(); // Try to reconnect
      }
    }, 1500); // Retry every 1.5 seconds
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isGenerating) return;
    if (socketState !== WebSocket.OPEN) return;
    // if inputMessage start with /, change channel
    if (inputMessage.startsWith("/")) {
      const channel = inputMessage.slice(1);
      if (channel === GET_CONTACT_PERSON) {
        currentChannelRef.current = GET_CONTACT_PERSON;
      } else {
        // generate error message
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: `${channel}은 잘못된 기능입니다.` },
        ]);
        setInputMessage("");
      }
      return;
    }
    setIsGenerating(true);
    // 사용자 메시지를 즉시 화면에 추가, socket 에러시에는 표시하지 않도록.
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: inputMessage },
    ]);
    setInputMessage("");
    // TODO Check message가 서버에 안전하게 전송되었는지 확인
    sendMessage(inputMessage);
    setGenerateMessage("\n");
  };

  const Message = ({ content }) => {
    return (
      <ReactMarkdown
        components={{
          a: ({ node, ...props }) => (
            <a target="_blank" rel="noopener noreferrer" {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div>
      {!isAuthenticated ? (
        <div className="auth-container">
          <div className="auth-form">
            <img src={assistantIcon} alt="Assistant" className="auth-icon" />
            <p>{mainError}</p>
          </div>
        </div>
      ) : (
        <div className="app-container">
          {isSidebarOpen && <Sidebar toggleSidebar={toggleSidebar} />}
          {!isSidebarOpen && (
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="open-sidebar"
            >
              <TfiMenuAlt />
            </button>
          )}
          <div
            className={`chat-container ${isSidebarOpen ? "sidebar-open" : ""}`}
          >
            <div className="chat-header">
              <p>
                {" "}
                <img
                  src={assistantIcon}
                  alt="Assistant"
                  className="assistant-icon"
                />{" "}
                KC CHATBOT
              </p>
            </div>

            <div className="messages-container">
              <ChatGuide />
              {socketState === WebSocket.CLOSED ? (
                <div className="message-wrapper assistant">
                  <div className="message assistant">
                    <div className="message-content error">
                      서버와의 연결이 끊겼습니다. 다시 시도해 주세요.
                    </div>
                  </div>
                </div>
              ) : socketState === WebSocket.CONNECTING ? (
                <div className="message-wrapper assistant">
                  <div className="message assistant">
                    <div className="message-content error">
                      서버와 연결중입니다...
                    </div>
                  </div>
                </div>
              ) : (
                messages.map((msg, index) => (
                  <div key={index} className={`message-wrapper ${msg.role}`}>
                    <div className={`message ${msg.role}`}>
                      {/* {msg.role === "assistant" && (
                        <img
                          src={assistantIcon}
                          alt="Assistant"
                          className="assistant-icon"
                        />
                      )} */}
                      <div className="message-content">
                        <Message content={msg.content} />
                      </div>
                    </div>
                    {msg.role === "assistant" && index !== 0 && (
                      <Feedback
                        question={messages[index - 1].content}
                        answer={messages[index].content}
                      />
                    )}
                  </div>
                ))
              )}
              {generateMessage.length > 0 && (
                <div className="message-wrapper assistant">
                  <div className="message assistant">
                    <div className="message-content generating">
                      <Message content={generateMessage} />
                    </div>
                  </div>
                </div>
              )}

              <div ref={messagesEndRef} />
            </div>
            <form className="input-form" onSubmit={handleSubmit}>
              <textarea
                type="text"
                ref={textareaRef}
                value={inputMessage}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                }}
                placeholder="챗봇에게 질문해 주세요"
                disabled={isGenerating}
                rows={1}
                onKeyPress={handleKeyPress}
              />
              {isGenerating ? (
                <button type="button" className="stop" onClick={stopGenerating}>
                  <FaStopCircle />
                </button>
              ) : (
                <button type="submit">{<IoSend />}</button>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
