import React, { useState } from "react";
import "./Feedback.scss";
import { MdOutlineThumbUp } from "react-icons/md";
import { MdOutlineThumbDown } from "react-icons/md";
import axios from "axios";

const ADMIN_URL = "https://admin-api.211.119.91.213.nip.io/api/";

const Feedback = ({ question, answer }) => {
  const [feedbackScore, setFeedbackScore] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleFeedback = (value) => {
    if (submitted) return; // 이미 제출된 경우 더 이상의 상호작용 방지
    setFeedbackScore(value);
  };

  const handleAdditionalFeedback = async () => {
    if (feedbackScore !== null) {
      const feedback = {
        score: feedbackScore,
        question: question,
        answer: answer,
        text: additionalFeedback,
      };

      try {
        const response = await axios.post(`${ADMIN_URL}feedback`, feedback);
        setSubmitted(true);
      } catch (error) {
        console.error("Error sending feedback:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    setAdditionalFeedback(e.target.value);
  };

  const handleInputBlur = () => {
    if (additionalFeedback.trim() === "") {
      setFeedbackScore(null);
    }
  };
  return (
    <div className="feedback-container">
      <div className="feedback-buttons">
        <button
          className={`feedback-button ${feedbackScore === 1 ? "active" : ""}`}
          onClick={() => handleFeedback(1)}
          disabled={submitted}
        >
          <MdOutlineThumbUp />
        </button>
        <button
          className={`feedback-button ${feedbackScore === 0 ? "active" : ""}`}
          onClick={() => handleFeedback(0)}
          disabled={submitted}
        >
          <MdOutlineThumbDown />
        </button>
      </div>
      {feedbackScore !== null && !submitted && (
        <div className="additional-feedback">
          <input
            type="text"
            value={additionalFeedback}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder="추가 의견을 입력해주세요 (선택사항)"
          />
          <button onClick={handleAdditionalFeedback}>제출</button>
        </div>
      )}
    </div>
  );
};
export default Feedback;
